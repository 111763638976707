
.container-itens-pedido{
    overflow-y: auto;
    height: 75%;
    border-bottom: 1px solid #575757;
}

.div-menu{
    display: flex;
    justify-content: center;
    margin-top: 5px;
}

.div-menu > span{
    font-size: 15px;
    color: #C8011E;
    font-style: italic;
    font-weight: 900;
}

.div-total-ped{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 70px;
    width: 98%;
    margin: 10px auto;
    padding: 0px 10px;
    border: 1px solid #575757;
    border-radius: 5px;
}

.div-total-ped > span{
    color: yellow;
    font-size: 17px;
    font-weight: bold;  
}

.div-total-ped > button{
    border: 0;
    background-color: transparent;
}

.sp-txt-preparacao{
    text-align: center;
    color: #fff;
    font-style: italic;
    font-size: 14px;
}

