

.containerImgRodape{
    background-color: #eee;
    width: 100%;
    height: 500px;
}

.boxRodape{
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 0 auto;
    max-width: 1280px;
    width: 100%;
    height: 500px;
}

.boximgRodape{
    height: 100%;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.boximgRodape img{
    width: 550px;
    border-radius: 10px;
    transition: all 0.2s;
}

.boximgRodape img:hover{
    transform: scale(1.03);
    transition: all 0.2s;
}

.txtRodape{
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.txtRodape > span{
    font-size: 40px;
    font-family: "Caveat Brush", cursive, sans-serif;
}

@media screen and (max-width: 980px) {

    .containerImgRodape{
        height: auto;
    }

    .boxRodape{
        flex-direction: column;
        height: auto;
    }

    .boximgRodape{
        width: 100%;
        padding-top: 20px;
    }

    .boximgRodape img{
        width: 90%;
    }

    .txtRodape{
        width: 100%;
        text-align: justify;
        padding: 10px 20px;
    }

    .txtRodape > span{
        font-size: 24px;
    }

}