
.containerTopo{
    height: 500px;
    width: 100%;
    margin-top: 5px;
    background-color: #f3f3f3;
}

.div-container-sec1{
    width: 100%;
    height: 100%;
    max-width: 1280px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.boximg img{
    width: 550px;
    border-radius: 10px;
    transition: all 0.2s;
}

.boximg img:hover{
    transform: scale(1.03);
    transition: all 0.2s;
}

.div-texto-banner1{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height: 430px;
    padding: 0px 20px;
}

.div-texto-banner1 span{
    font-family: "Caveat Brush", cursive;
    font-weight: 400;
    font-size: 32px;
    text-align: justify;
    color: #000;
}

.boxBtn{
    width: 500px;
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.boxBtn a{
    background-color: var(--red-900);
    padding: 20px;
    border-radius: 30px;
    text-align: center;
    width: 100%;
    height: 100%;
    color: #fff;
    font-weight: bold;
    box-shadow: 3px 3px 3px rgba(0,0,0,0.3);
}

.boxBtn a:hover{
    background-color: rgb(168, 0, 0);
}


/* MOBILE */

@media screen and (max-width: 980px) {

    .containerTopo{
        height: auto;
        padding-top: 5px;
    }

    .div-container-sec1{
        flex-direction: column;
    }

    .boximg{
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .boximg img{
        width: 90%;
    }

    .div-texto-banner1 {
        height: auto;
        margin: 20px 0;
    }

    .div-texto-banner1 span{
        font-size: 18px;
    }

    .boxBtn{
        display: none;
    }
}