

.containerCategoria{
    background-color: black;
    height: calc(100vh - 135px);
    width: 100%;
    overflow: auto;
}

.contentcategoria{
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
    height: calc(100vh - 135px);
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #575757;
    border-radius: 5px;
}

.contentcategoria h2{
    color: var(--white);
    font-size: 25px;
    font-style: italic;
    text-align: center;
    padding: 10px 0;
}

.new{
    display: flex;
    margin: 10px 0 5px 0;
    width: 500px;
    max-width: 500px;
    border: 1px solid var(--gray-100);
    border-radius: 5px;
    height: 40px;
}

.new input{
    width: 80%;
    height: 100%;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.new button{
    width: 130px;
    height: 100%;
    border-radius: 0;
    font-size: 14px;
}

.btnCancel{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 10%;
    height: 100%;
}

.btnCancel button{
    height: 100%;
    border: 0;
    background-color: var(--red-900);
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.btnCancel button:hover{
    background-color: rgb(168, 0, 0);
}

.div-pesquisa-categoria{
    border: 1px solid var(--gray-100);
    border-radius: 5px;
    width: 500px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.div-pesquisa-categoria label{
    color: #fff;
    font-size: 14px;
    font-weight: bold;
    padding: 0px 5px;
}

.div-pesquisa-categoria input{
    height: 100%;
    width: 100%;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    color: #000;
    padding: 5px;
}


.containerlista{
    display: flex;
    flex-direction: row;
    width: 500px;
    max-width: 500px;
    margin: 0 auto;
    height: calc(100vh - 285px);
    padding-left: 10px;
}

.containerlista ul{
    width: 100%;
    border-radius: 5px;
    margin-top: 20px;
    overflow-y: auto;
}

.containerlista li{
    display: flex;
    flex-direction: row;
    padding: 10px;
    margin: 5px 0;
    font-size: 15px;
    font-weight: bold;
    align-items: center;
    justify-content: space-between;
    color: var(--white);
    border: 1px solid var(--gray-100);
    border-radius: 5px;
    width: 98%;
   
}

.containerlista li button{
    border: 0;
    background-color: transparent;
    padding: 10px;
    border-radius: 5px;
    margin: 0 5px; 
}

.containerlista li button:hover{
    background-color: rgb(168, 0, 0);
}







