
.containerplanos{
    height: 900px;
    width: 100%;
    background-color: #f3f3f3;
}

.div-container-planos{
    height: 100%;
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
}

.planos-texto-container{
    height: 200px;
}

.planos-texto-container h1{
    font-family: "Caveat Brush", cursive, sans-serif;   
    font-optical-sizing: auto;
    font-weight: weight;
    font-style: italic;
    font-size: 65px;
}

.planos-texto-container h3{
    margin-bottom: 40px;
    font-family: "Caveat Brush", cursive, sans-serif;   
    font-optical-sizing: auto;
    font-weight: weight;
    font-style: italic;
    color: #575757;
    font-size: 24px;
}

.planos-texto-container h4{
    margin-bottom: 44px;
    font-family: "Caveat Brush", cursive, sans-serif;   
    font-optical-sizing: auto;
    font-weight: weight;
    font-style: italic;
    color: #575757;
    font-size: 24px;
}

.div-planos-valores{
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding-top: 10px;
    padding-bottom: 10px;
}

.div-plano1, .div-plano2{
    background-color: #e9e9e9;
    height: 650px;
    width: 30%;
    border-radius: 20px;
    box-shadow: 2px 2px 5px rgba(0,0,0,0.2);
}

.div-plano3{
    background-color: #ddd;
    height: 650px;
    width: 30%;
    border-radius: 20px;
    box-shadow: 2px 2px 5px rgba(0,0,0,0.2);
}

.div-plano1 h2, .div-plano2 h2, .div-plano3 h2{
    text-align: center;
    color: #737373;
    font-style: italic;
    padding: 5px;
    height: 35px;
}

.plano-mod3{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}

.div-plano1:hover, .div-plano2:hover{
    background-color: #ddd;
}

.div-plano3:hover{
    background-color: #ccc;
}

.div-plano3 h2{
    color: #575757 !important;
}

.plano-valores{
    height: 85%;
    display: flex;
    flex-direction: column;
}

.plano-div-valores{
    text-align: center;
}

.plano-div-valores span.spanvalor{
    font-size: 38px;
    color: #737373;
    font-weight: bold;
}

.plano-div-valores span.spanmes{
    font-size: 12px;
    color: #737373;
    font-style: italic;
}

.plano-valores a{
    border: 0;
    background-color: var(--red-900);
    height: 40px;
    margin: 20px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-weight: bold;
    font-size: 16px;
}

.plano-valores a:hover{
    background-color: rgb(168, 0, 0);
    box-shadow: 2px 2px 2px rgba(0,0,0,0.2);
}

.plano-valores ul{
    list-style: none;
}

.plano-valores ul li{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 15px 0 15px 0;
    padding-left: 12px;
    color: #575757;
    font-size: 16px;
    font-family: "Poppins", sans-serif;
    font-optical-sizing: auto;
    font-style: italic;
}

.plano-valores ul li svg{
    margin-right: 10px;
}

.plano-valores ul.ul-mod3 li{
    color: #000;
}

.plano-div-valores span.spanvalor3{
    font-size: 38px;
    color: #575757;
    font-weight: bold;
}

.plano-div-valores span.spanmes3{
    font-size: 12px;
    color: #000;
    font-style: italic;
}

.planos-txtbtn{
    display: none;
    align-items: center;
    justify-content: center;
    background-color: var(--red-900);
    height: 35px;
    width: 90%;
    margin: 10px auto;
    border-radius: 25px;
    opacity: 0;
}

.planos-txtbtn span{
    color: #fff;
    font-weight: bold;
    font-size: 13px;
}

/* MOBILE */

@media screen and (max-width: 980px) {

    .containerplanos{
        height: auto;
    }

    .div-container-planos{
        height: auto;
    } 
    
    .planos-texto-container{
        height: auto;
    }

    .planos-texto-container h1{
        font-size: 28px;
        padding: 20px 10px;
    }

    .planos-texto-container h3{
        margin-bottom: 10px;
    }

    .planos-texto-container h3, .planos-texto-container h4{
        font-size: 18px;
        text-align: justify;
        padding: 10px;
    }

    .div-planos-valores{
        flex-direction: column;
        gap: 10px;
    }

    .div-plano1, .div-plano2, .div-plano3{
        width: 90%;
        height: 630px;
    }

    .plano-valores ul li{
        font-size: 14px;
    }

    .plano-valores a{
        display: none;
    }

    .planos-txtbtn{
        display: flex;
        opacity: 1;
    }
}
