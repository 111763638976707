
.contentModalDetalhe{
    background-color: #bbb;
    position: fixed;
    max-width: 600px;
    width: 550px;
    height: 450px;
    top: 5%;
    left: 0;
    right: 0;
    margin: 0 auto;
    border-radius: 8px; 
    padding: 15px;
}

.headerDetalhe{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.itensDetalhe{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 5px 0;
    border: 1px solid #575757;
    border-radius: 5px;
    height: 180px;
    overflow-y: auto;
}

.notborder{
    border: 0;
    margin-left: 8px;
    margin-top: 8px;
}

.itensDetalhe h4{
    font-size: 14px;
    font-weight: bold;
}

.itensDetalhe span{
    margin: 5px 0px;
    font-size: 12px;
    font-style: italic;
}

.div-obspedido{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100px;
    border: 1px solid #575757;
    border-radius: 5px;
    padding: 8px;
    overflow-y: auto;
}

.div-obspedido > label{
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 5px;
}

.div-obspedido > span{
    font-size: 15px;
    font-weight: bold;
    color: red;
}  

.pedidomesa{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 490px;
}

.pedidomesa > h4{
    font-size: 17px;
}

.btnCloseModal{
    display: flex;
    align-items: center;
}

.btnCloseModal button{
    border: 0;
    padding: 8px;
    background-color: var(--red-900);
    color: var(--white);
    border-radius: 5px;
    display: flex;
}

.btnCloseModal button:hover{
    background-color: red;
    transform: scale(1.1);
}

.btnCloseModal > h2{
    margin-left: 80px;
}

.boxbtn{
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 60px;
}

.btnImprimir{
    border: 0;
    padding: 10px;
    background-color: rgb(4, 4, 185);
    border-radius: 5px;
    color: var(--white);
    font-size: 14px;
    font-weight: bold;
    display: flex;
    align-items: center; 
    justify-content: center;
    width: 150px; 
    gap: 10px;
}

.btnImprimir:hover{
    background-color: blue;
    box-shadow: 1px 1px 5px rgba(0,0,0,0.5);
}

.btnpreparacao{
    border: 0;
    padding: 10px;
    background-color: var(--red-900);
    border-radius: 5px;
    color: var(--white);
    font-size: 14px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 190px;
}

.btnpreparacao > svg{
    margin-right: 5px;
}

.btnpreparacao:hover{
    background-color: red;
    box-shadow: 1px 1px 5px rgba(0,0,0,0.5);
}

.btnEntregue{
    border: 0;
    padding: 10px;
    background-color: green;
    border-radius: 5px;
    color: var(--white);
    font-size: 14px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 150px;
}

.btnEntregue > svg{
    margin-right: 5px;
}

.btnEntregue:hover{
    background-color: rgb(3, 180, 3);
    box-shadow: 1px 1px 5px rgba(0,0,0,0.5);
}

.printItensPedido{
    display: none;
    flex-direction: column;
    background-color: #fff;
    width: 350px;
    height: auto;
}

.printInfo{
    margin-bottom: 20px;
}

.printInfo > h4{
    font-size: 20px;
    font-weight: 200;
}

.printInfo > h5{
    font-size: 18px;
    font-weight: normal;
}

.printHeader{
    margin-bottom: 20px;
}

.printHeader > h4{
    font-size: 22px;
    font-weight: 700;   
}

.printHeader > span{
    font-size: 18px;
}

.printDetail{
    margin-bottom: 5px;
}

.printDetail > span{
    font-size: 18px;
}

.print-obs{
    padding: 20px 0px 20px 0;
}

.print-obs > h6{
    font-size: 14px;
    font-weight: normal;
}

.print-obs > span{
    font-size: 15px;
    font-weight: normal;
}

.printFooter{
    border-bottom: 1px dashed #000;
    margin-right: 10px;
    margin-bottom: 5px;
}

.printFooter > h5{
    font-size: 12px;
    font-weight: normal; 
    text-align: right; 
    padding-bottom: 10px;  
}

@media print {
    .printItensPedido{
        display: flex;
    }

    .contentModalDetalhe{
        display: none;
    }
}
