
.headerhome-container{
    background-color: black;
    height: 120px;
    width: 100%;
    box-shadow: 3px 3px 3px rgba(0,0,0,0.6);
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
    margin-top: 40px;
}

.headerhome-topo{
    background-color: var(--red-900);
    height: 40px;
    position: fixed;
    z-index: 99;
    top: 0;
    width: 100%;
}

.headerhome-topo ul{
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 1280px;
    width: 100%;
    margin: 0 auto;
    height: 40px;
    gap: 50px;
}

.headerhome-topo ul > li{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.headerhome-topo ul li:last-child{
    background-color: var(--green-900);
    width: 200px;
    height: 30px;
    border-radius: 15px;
    cursor: pointer;
}

.headerhome-topo ul li:last-child:hover{
    background-color: green;
}

.headerhome-topo ul li:last-child > a{
    font-weight: bold;
    font-size: 12px;
    text-decoration: none !important;
    color: #fff;
}

.headerhome-topo ul li.acessar{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    font-size: 14px;
    font-weight: bold;
}

.headerhome-topo ul li > a:hover{
    text-decoration: underline;
}

.headerhome-topo ul li, .headerhome-topo ul li a{
    color: #fff;
}

/* HOME NAVEGACAO */

.headerhome-menu-mobile{
    display: none;
    padding: 10px;
}

.headerhome-full{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 120px;
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
    padding: 0px 15px;
}

.headerhome-full nav ul{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    list-style: none;
}

.headerhome-full nav ul li{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
    gap: 5px;
    transition: all 0.3s;
    border-radius: 5px;
}

.headerhome-full nav ul li:hover{
    border: 1px solid var(--green-900);  
    transform: scale(1.07); 
    transition: all 0.3s;   
}

.headerhome-full nav ul li a{
    font-size: 14px;
    font-weight: bold;
    color: #fff;
}

/* MOBILE */

@media screen and (max-width: 980px) {

    .headerhome-container{
        margin-top: 0;

    }

    .headerhome-menu-mobile{
        display: block;
    }   

    .headerhome-topo, .headerhome-full nav{
        display: none;
    }  
    


}
