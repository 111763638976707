
.ocultarLinha{
    display: none !important;
}

.containerProduto{
    background-color: black;
    height: calc(100vh - 135px);
    width: 100%;
    overflow: auto;
}

.div-content-produto{
    border: 1px solid #575757;
    height: 100%;
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
    border-radius: 5px;
    overflow: auto;
}

.menuCad{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 0px;
    border-bottom: 1px solid #575757;
}

.menuCad h2{
    margin-right: 30px;
    color: var(--white);
    font-size: 18px;
    font-style: italic;
}

.menuCad a{
    color: #fff;
    background-color: green;
    width: 150px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    font-size: 14px;
    font-weight: bold;
}

.menuCad a svg{
    margin-right: 5px;
}

.menuCad a:hover{
    background-color: var(--green-900);
}

.div-pesquisa{
    width: 100%;
    max-width: 500px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin: 0 auto;
}

.div-pesquisa > label{
    color: white;
    font-size: 14px;
    font-weight: bold;
}

.div-pesquisa > input{
    height: 25px;
    width: 300px;
    border: 0;
    border-radius: 5px;
    padding: 10px;
    text-transform: uppercase;
}

.table{
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
    height: calc(100vh - 235px);
    overflow: auto;
}

.table table{
    border: 1px solid var(--gray-100);
    width: 100%;
}

.table thead{
    background-color: var(--red-900);
}

.table thead tr th{
    color: var(--white);
    font-size: 15px;
    font-style: italic;
    height: 30px;
}

.c1{
    width: 5%;
}

.c2{
    width: 7%;
}

.c3{
    width: 38%;
}

.c4{
    width: 25%;
}

.c5{
    width: 15%;
}

.c6{
    width: 10%;
}

.table tbody td{
    height: 30px;
    color: var(--white);
    font-size: 17px;
}

.div-produtos-cad:nth-child(even) {
    background-color: #191919;
}

.table tbody tr:hover{
    background-color: #575757;
}

.td-cardapio{
    text-align: center;
}

.foto-prod{
    text-align: center;
}

.foto-prod img{
    object-fit: cover;
    text-align: center;
    width: 60%;
    height: 50px;
    border-radius: 5px;
}

.div-empty{
    width: 1000px;
    max-width: 1000px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 30px;
}

.div-empty > span{
    color: #fff;
    font-size: 18px;
    font-style: italic;
    margin-top: 20px;
}

.imgProduto{
    width: 60px;
    height: 60px;
    border-radius: 5px;
}

.btnAcao{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 10px;
}

.btnAcao button{
    border: 0;
    width: 35px;
    height: 35px;
    background-color: transparent;
    margin: 0 5px;
    padding: 5px;
    border-radius: 5px;
}

.btnAcao button:hover{
    background-color: rgb(168, 0, 0);
}