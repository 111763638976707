

.footer{
    position: fixed;
    bottom: 0;
    right: 0;
    width: 100%;
    background-color: black;
    height: 15px;
}

.version{
    color: aliceblue;
    font-size: 10px;
    font-style: italic;
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1280px;
    width: 100%;
    margin: 0 auto;
}

.version > h3 > a{
    color: aliceblue;
    text-decoration: underline;
}

@media screen and (max-width: 980px) {
    .version{
        font-size: 9px;
    }
}