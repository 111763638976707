
.sec-container-novopedido{
    background-color: black;
    height: calc(100vh - 135px);
    width: 100%;
    overflow: auto;
}

.div-content-novopedido{
    border: 1px solid #575757;
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
    height: calc(100vh - 135px);
    overflow: auto;
    border-radius: 5px;
    display: flex;
}

.div-header-novopedido{
    width: 50%;
    height: 100%;
    overflow: auto;
}

.div-titulo-pedido{
    display: flex;
    align-items: center;
    gap: 10px;
    border-bottom: 1px solid #575757;
    border-right: 1px solid #575757;
}

.div-titulo-pedido > button{
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border: 0;
}

.div-titulo-pedido span{
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    font-style: italic;
}

.form-novo-pedido{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 10px;
    gap: 10px;
    border-right: 1px solid #575757;
}

.form-novo-pedido #mesa{
    width: 90px;
    height: 40px;
}

.form-novo-pedido #nome{
    width: 300px;
    height: 40px;
}

.form-novo-pedido div > button{
    width: 150px;
    height: 40px;
    border: 0;
    background-color: green;
    border-radius: 5px;
    color: #fff;
    font-size: 14px;
    font-weight: bold;
}

.form-novo-pedido div > button:hover{
    background-color: var(--green-900);
}

.mesaAberta{
    height: 80px;
    border-bottom: 1px solid #575757;
    border-right: 1px solid #575757;
}

.mesaAberta h3{
    color: var(--white);
    font-size: 22px;
    padding: 10px;
}

.mesaAberta h5{
    text-align: right;
    font-size: 22px;
    color: #fff;
    padding-right: 10px;
}

.div-itenspedido{
    height: 150px;
    padding: 0 10px;
    margin-top: 10px;
}

.div-itenspedido h2{
    color: var(--red-900);
    font-size: 18px;
    font-weight: bold;
    font-style: italic;
    text-align: center;
}

.div-selectitens{
    display: flex;
    gap: 10px;
}

.div-cat{
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.div-cat label{
    font-size: 14px;
    font-weight: bold;
    font-style: italic;
    color: #fff;
}

.div-cat select, .div-prod select{
    height: 40px;
    width: 100%;
    background-color: var(--dark-700);
    border-radius: 5px;
    color: var(--white);
    padding: 5px;
    font-size: 13px;
}

.div-prod{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 60%;
}

.div-prod label, .div-form-quant label, .div-ped-obs label{
    font-size: 14px;
    font-weight: bold;
    font-style: italic;
    color: #fff;
}

.form-addquantidade{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 70px;
}

.div-form-quant{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 200px;
}

.div-form-quant #idvalor{
    width: 120px;
    height: 35px;
    border: 1px solid #bbb;
}

.div-form-quant #idquant{
    width: 120px;
    height: 35px;
    border: 1px solid #bbb;
}

.div-btn-form{
    width: 30%;
    height: 40px;
}

.div-btn-form button{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    color: #fff;
    font-size: 14px;
    font-weight: bold;
    border-radius: 5px;
    border: 0;
    background-color: green;
}

.div-btn-form button:hover{
    background-color: var(--green-900);
}

.div-ped-obs{
    height: 120px;
    display: flex;
    flex-direction: column;
}

.div-ped-obs textarea{
    height: 100%;
    resize: none;
    border-radius: 5px;
    color: #000;
    padding: 5px;
}

/* itens pedido */

.div-header-itenspedido{
    width: 50%;
    height: 100%;
    overflow: auto;
    border-left: 1px solid #575757;
}

.emptyItens{
    text-align: center;
    width: 100%;
}

.emptyItens span{
    color: #fff;
    font-style: italic;
    font-weight: bold;
    font-size: 16px;
}

.table_itens{
    height: 100%;
    overflow: auto;
}

.table_itens table{
    width: 100%;
}

.table_itens thead{
    background-color: var(--red-900);
    height: 30px;
}

.table_itens thead tr th{
    color: var(--white);
    font-size: 15px;
    font-style: italic;
    height: 30px;
}

.it1{
    width: 45%;
}

.it2{
    width: 15%;
}

.it3{
    width: 15%;
}

.it4{
    width: 15%;
}

.it5{
    width: 10%;
}

.table_itens tbody td{
    height: 30px;
    color: var(--white);
    font-size: 14px;
    font-weight: bold;
}

.table_itens table tbody tr:nth-child(even) {
    background-color: #191919;
}

.table_itens table tbody tr:hover{
    background-color: #575757;
}

.btncancelaitem{
    display: flex;
    align-items: center;
    justify-content: center;
}

.btncancelaitem button{
    border: 0;
    padding: 5px;
    background-color: transparent;
}

.btncancelaitem button:hover{
    background-color: #575757;
    border-radius: 3px;
}

.btnConcluir{
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 60px;
}

.btnConcluir #ok{
    width: 220px;
    height: 35px;
    background-color: green;
    font-size: 14px;
    font-weight: bold;
    gap: 5px;
}

.btnConcluir #cancel{
    width: 220px;
    height: 35px;
    background-color: var(--red-900);
    font-size: 14px;
    font-weight: bold;
    gap: 5px;
}

.btnConcluir #ok:hover{
    background-color: var(--green-900)
}

.btnConcluir #cancel:hover{
    background-color: rgb(168, 0, 0);
}


/* 
.sec-container-novopedido{
    display: flex;
    align-items: flex-start;
    height: calc(100vh - 200px);
    overflow-y: auto;
    width: 100%;
}

.containerNovoPedido{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    max-width: 50%;
    width: 100%;
    margin-left: 20px;
    border: 1px solid var(--gray-100);
    border-radius: 5px;
    margin-top: 10px;
    height: 350px;
    overflow: auto;
    gap: 10px;
    box-shadow: 2px 2px 2px rgba(255,255,255,0.4);
}

.containerNovo{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    margin-left: 10px;
}

.btnback{
    color: var(--white);
    margin: 10px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: bold;
}

.btnback > button{
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border: 0;
    margin-right: 5px;
}

.btnback button:hover{
    background-color: var(--gray-100);
}

.headerNovoPedido{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 45px;
    border: 1px solid var(--gray-100);
    border-radius: 5px;
    padding: 3px;
}

.headerNovoPedido #mesa{
    width: 70px;
    height: 35px;
    margin-right: 15px;
}

.headerNovoPedido #nome{
    width: 130px;
    height: 35px;
    margin-right: 15px;
}

.headerNovoPedido button{
    width: 70px;
    height: 35px;
    margin-top: 20px;
}

.mesaAberta{
    display: flex;
    flex-direction: column;
}

.mesaAberta h3{
    margin-top: 10px;
    color: var(--white);
    border-bottom: 1px solid var(--green-900);
    font-size: 16px;
}

.mesaAberta h5{
    text-align: center;
    font-size: 18px;
    margin: 22px 0;
    color: #fff;
}

.div-ped-obs{
    border: 1px solid var(--gray-100);
    border-radius: 5px;
    padding: 10px;
    width: 310px;
    height: 100px;
    display: flex;
    flex-direction: column;
}

.div-ped-obs > label{
    font-size: 12px;
    color: #fff;
    font-style: italic;
}

.div-ped-obs > textarea{
    resize: none;
    height: 80px;
    border-radius: 5px;
    text-transform: uppercase;
    padding: 5px;
    background-color: #eee;
    border: 0;
}

.tagdiv{
    width: 2px;
    background-color: var(--gray-100);
    height: calc(100vh - 100px);
}

.itenspedido{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -62px;
}

.itenspedido h2{
    margin: 10px 0;
    color: var(--white);
}

.selectitens{
    display: flex;
    flex-direction: column;
    justify-content: baseline;
    width: 330px;
    margin-right: 5px;
}

.selectitens select{
    margin-bottom: 15px;
    height: 35px;
    background-color: var(--dark-700);
    border-radius: 5px;
    color: var(--white);
    padding: 5px;
    font-size: 13px;
}

.addquantidade{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 330px;
    height: 60px;
    border: 1px solid var(--gray-100);
    border-radius: 5px;
    margin-right: 5px;
}

.addquantidade div{
    display: flex;
    flex-direction: column;
}

.addquantidade div label{
    color: #fff;
    font-size: 13px;
    font-style: italic;
}

.addquantidade input{
    width: 110px;
    height: 35px;
    margin-right: 15px;
    margin-top: 1px;
    border: 1px solid var(--gray-100);
}

.addquantidade button{
    width: 70px;
    height: 35px;
    font-size: 15px;
}

.addquantidade button:hover{
    background-color: green;
    color: var(--white);
}

.table_itens{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 45%;
    max-width: 1000px;
    margin: 0 auto;
    margin-top: 10px;
    height: calc(100vh - 350px);
    overflow-y: auto;
}

.table_itens table{
    border-radius: 2px;
    border: 1px solid var(--gray-100);
}

.table_itens thead{
    background-color: rgb(170, 3, 3);
}

.table_itens thead th{
    color: var(--white);
    font-size: 12px;
    padding: 5px;
}

.table_itens tbody td{
    border: 1px solid var(--gray-100);
    color: var(--white);
    font-size: 15px;
}

.emptyItens{
    width: 100%;
    text-align: center;
}

.emptyItens span{
    color: var(--white);
    font-size: 25px;
    font-style: italic;
}

.btncancelaitem{
    display: flex;
    align-items: center;
    justify-content: center;
}

.btncancelaitem > button{
    border: 0;
    padding: 5px;
    background-color: transparent;
}

.btncancelaitem > button:hover{
    background-color: red;
    border-radius: 3px;
}

.btnConcluir{
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    bottom: 0;
    margin: 20px auto;
    width: 100%;
    border-bottom: 1px solid #ccc;
    padding-bottom: 5px;
}

.btnConcluir #ok{
    width: 220px;
    height: 35px;
    border: 1px solid var(--gray-100);
    background-color: transparent;
    font-size: 16px;
}

.btnConcluir #cancel{
    width: 220px;
    height: 35px;
    border: 1px solid var(--red-900);
    background-color: transparent;
    margin-left: 50px;
    font-size: 16px;
}

.btnConcluir button svg{
    margin: 0 10px;
}

.btnConcluir #ok:hover{
    background-color: green;
}

.btnConcluir #cancel:hover{
    background-color: var(--red-900);
} */
