
.loading{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    height: 300px;
    width: 300px;
    flex-direction: column;
    margin: 0 auto;
}

.loading h4{
    color: var(--white);
    font-style: italic;
    font-size: 25px;
    margin-top: 40px;
}

.loading svg{
    animation: loadingsvg infinite 5s linear alternate;
}

@keyframes loadingsvg {
    0%{
        rotate: 0deg;
        color: #fff;
        transform: scale(1);
    }
    50%{
        rotate: 180deg;
        color: #ff0;
    }
    100%{
        rotate: 360deg;
        color: #EA1C24;
        transform: scale(1.30);
    }
}