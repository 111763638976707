
.div-container-fechamento{
    background-color: #f3f3f3;
    position: fixed;
    max-width: 700px;
    width: 100%;
    height: 100%;
    max-height: 60%;
    top: 5%;
    left: 0;
    right: 0;
    margin: 0 auto;
    border-radius: 8px; 
    padding: 15px;
}

.fechamento{
    border-bottom: 2px solid #000;
    padding-bottom: 5px;
}

.fechamento > h4{
    margin-left: 30px;
    text-transform: uppercase;
    font-size: 26px;
}

.div-overflow-modal{
    width: 100%;
    height: 93%;
    overflow: auto;
    border: 1px solid #575757;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}

.container-itens{
    height: 170px;
    overflow-y: scroll;
    overflow-x: auto;
    border: 1px solid #fff;
    border-radius: 5px;
    margin-top: 5px;
}

.itensmesa{
    list-style: none;
}

.itensmesa li{
    font-size: 15px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 10px;
    border: 1px solid #575757;
}

.pagamentos{
    display: flex;
}

.div-forma-pagamento{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 60%;
    padding-left: 10px;
}

.div-forma-pagamento span{
    font-size: 16px;
    font-weight: bold;
    font-style: italic;
    margin-top: 10px;
}

.div-forma-pagamento select{
    height: 35px;
    width: 80%;
    color: var(--black);
    border-radius: 5px;
    font-size: 17px;
    padding: 5px;
}

.pagamentos .checkAtendimento{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;   
}

.checkAtendimento label{
    font-style: italic;
    cursor: pointer;
    font-size: 14px;
    font-weight: normal;
}

.checkAtendimento input[type='checkbox']{
    width: 17px;
    height: 17px;
    cursor: pointer;
}

.cont-valores{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 10px;
    height: 130px;
}

.div-nomesMesa{
    height: 130px;
    width: 40%;
    overflow-y: auto;
    overflow-y: scroll;
}

.nomes-mesa{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 10px;
}

.nomes-mesa > p{
    color: #000;
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
}

.valores{
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-direction: column;
    height: 130px;
    width: 60%;
    padding-right: 10px;
}

.valores > h4{
    font-size: 18px;
    font-weight: bold;
    color: blue;
}

.valores > h3{
    font-style: bold;
    font-size: 24px;
    color: var(--red-900);
}

.btnFecharMesa{
    height: 100px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.btnFecharMesa button{
    width: 200px;
    color: white;
    height: 40px;
    font-size: 14px;
    font-weight: bold;
    gap: 5px;
}

.btnFecharMesa #btnImpComanda{
    border: 0;
    padding: 10px;
    background-color: rgb(4, 4, 185);
    border-radius: 5px;
    color: var(--white);
    font-size: 14px;
    font-weight: bold;
    display: flex;
    align-items: center;  
    justify-content: center; 
}

#btnImpComanda:hover{
    background-color: blue;
}

.printComanda{
    display: none;
    flex-direction: column;
    background-color: #fff;
    width: 350px;
    height: auto;
}

.printNome{
    margin-bottom: 20px;
}

.printNome > h4{
    font-size: 32px;
    font-weight: bold;
    font-family: "Caveat Brush", cursive, sans-serif;
}

.printNome > h5{
    font-size: 18px;
    font-weight: normal;
}

.printHeaderMesa{
    padding: 10px 0;
    margin-bottom: 20px;
}

.printHeaderMesa > h5{
    font-size: 28px;
    font-weight: bold;
    font-family: "Caveat Brush", cursive, sans-serif;
}

.printDetailMesa{
    margin-bottom: 15px;
    border-bottom: 1px dashed #000;
}

.printDetailMesa > h4{
    font-size: 20px;
    font-weight: 500;
}

.printDetailMesa > span{
    font-size: 16px;
}

.printSubTotal{
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    text-align: right;
    margin-right: 10px;
}

.printSubTotal #idsubtotal, #idtaxa{
    font-size: 18px;
} 

.printSubTotal #idtotal{
    font-size: 24px;
}

.printrodape{
    margin-right: 10px;
    margin-top: 15px;
}

.printrodape > h5{
    font-size: 12px;
    font-weight: normal;
    text-align: right;
    padding-bottom: 5px;
    border-bottom: 1px dashed #000;
}


@media print {
    .printComanda{
        display: flex;
    }

    .div-container-fechamento{
        display: none;
    }
}
