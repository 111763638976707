
.footer-container{
    background-color: #000;
    height: 350px;
    width: 100%;
}

.containter-footer{
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
    height: 100%;
}

.footer-section{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 300px;
}

.section1{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 25%;
    height: 100%;
    padding-top: 20px;
    gap: 15px;
}

.section1 h3, .section2 h3, .section3 h3{
    font-family: "Poppins", sans-serif;
    font-size: 22px;
    font-weight: bold;
    margin-top: 10px;
    font-style: italic;
    color: #fff;
}

.section1 span, .section3 span{
    font-size: 16px;
    margin-top: 10px;
    font-style: italic;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.section2, .section3{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 25%;
    height: 100%;
    gap: 15px;
    padding: 20px 0px 0px 20px;
}

.section2 a{
    color: #fff;
    font-size: 14px;
    width: 120px;
    height: 18px;
    border-radius: 5px;
}

.section2 a:hover{
    background-color: var(--blue100);
    transition: all 0.5s;
    box-shadow: 3px 3px 3px rgba(0,0,0,0.4);
    text-decoration: underline;
}

.footer-info{
    text-align: center;
}

.footer-info span, .footer-info a{
    color: #fff;
    font-size: 12px;
    font-style: italic;
}

.footer-info a{
    text-decoration: underline;
}

@media screen and (max-width: 980px) {
    .footer-container{
        height: auto;
    }

    .footer-section{
        flex-direction: column;
        height: auto;
    }

    .section1, .section3{
        width: 100%; 
        padding: 20px 0px 5px 20px; 
        border-bottom: 2px solid #aaa;      
    }

    .section2{
        display: none;
    }

    .footer-info{
        text-align: center;
        padding: 10px;
    }

}

