
.containerL{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #000;
}

.containerLogin{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 2px solid var(--green-900);
    width: 500px;
    height: 350px;
    border-radius: 10px;
    background-color: #000;
    box-shadow: 3px 3px 10px rgba(255,255,255,0.5);
}

.containerLogin h3{
    color: var(--white);
    font-style: italic;
    margin-bottom: 10px;
}

.containerLogin form{
    display: flex;
    flex-direction: column;
    width: 90%;
}

.boxSenha{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    max-width: 500px;
    width: 100%;
}

.boxSenha > input{
    width: 500px;
}

.div-linha-validacao{
    display: flex;
    align-items: center;
    height: 40px;
    border: 1px solid var(--gray-100);
    background-color: var(--dark-700);
    border-radius: 5px;
    padding-left: 10px;
    width: 90%;
    margin-bottom: 10px;
}

.btn-validaremail{
    border: 0;
    height: 100%;
    width: 80px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: green;
    color: #fff;
    font-size: 14px;
    font-weight: bold;
    font-style: italic;
}

.btn-validaremail:hover{
    background-color: var(--green-900);
    color: var(--white);
}


