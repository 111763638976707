
.containerModal{
   position: fixed;
   top: 0;
   bottom: 0;
   left: 0;
   right: 0;
   background-color: rgba(0,0,0,0.7);
   z-index: 99;
}

.contentModal{
    background-color: var(--white);
    position: fixed;
    max-width: 400px;
    width: 400px;
    height: 300px;
    top: 20%;
    left: 0;
    right: 0;
    margin: 0 auto;
    border-radius: 8px; 
    padding: 15px;
    box-shadow: 0 0 5px black;
}

.headerModal{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100px;
}

.headerModal h3{
    margin-top: 10px;
}

.contentItem{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.contentItem h3{
    font-size: 20px;
    margin: 25px 0;
    padding: 10px;
}

.contentButton{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    padding: 0px 15px;
}

.contentButton button{
    width: 100px;
    margin: 0 10px;
    border: 0;
    height: 35px;
    border-radius: 5px;
    color: var(--white);
    font-size: 15px;
    font-weight: bold;
    width: 150px;
}

.contentButton #ok{
    background-color: var(--red-900);
}

.contentButton #ok:hover{
    background-color: rgb(168, 0, 0);
}

.contentButton #cancel{
    background-color: var(--gray-100);
}

.contentButton #cancel:hover{
    background-color: rgba(0, 0, 0, 0.671);
}




