

.containerCaixa{
    background-color: black;
    height: calc(100vh - 135px);
    width: 100%;
    overflow: auto;
}

.div-content-caixa{
    border: 1px solid #575757;
    height: 100%;
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
    overflow: auto;
    border-radius: 5px;
}

.div-estilocaixa{
    border-bottom: 1px solid #575757;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 50px;
    height: 70px;
}

.div-content-caixa h2{
    font-size: 22px;
    font-weight: bold;
    color: #fff;    
}

.div-estilocaixa button{
    width: 170px;
    background-color: transparent;
    border: 1px solid var(--red-900);
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 14px;
    font-weight: bold;
}

.div-estilocaixa button:hover{
    background-color: var(--green-900);
    border: 1px solid var(--green-900);
    color: #000;
}

.div-lancarvalores{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    height: 60px;
    border-bottom: 1px solid #575757;
}

.div-lancarvalores #valor{
    width: 10%;
}

.div-lancarvalores #desc{
    width: 400px;
}

.div-lancarvalores button{
    width: 150px;
    font-size: 14px;
    font-weight: bold;
    height: 35px;
}

.div-lancarvalores #deb{
    background-color: var(--red-900);
}

.div-lancarvalores #deb:hover{
    background-color: rgb(168, 0, 0);
}

.tablecaixa{
    height: calc(100vh - 270px);
    overflow: auto;
}

.tablecaixa table{
    width: 100%;
}

.caixavazio{
    margin-top: 20px;
    display: flex;
    justify-content: center;
}

.caixavazio h2{
    color: var(--white);
    font-style: italic;
}

.tablecaixa table thead{
    background-color: var(--red-900);
}

.tablecaixa thead tr th{
    color: var(--white);
    font-size: 15px;
    font-style: italic;
    height: 30px;
}

.cx1{
    width: 10%;
}

.cx2{
    width: 20%;
}

.cx3{
    width: 40%;
}

.cx4{
    width: 15%;
}

.cx5{
    width: 15%;
}

.tablecaixa table tbody tr td{
    color: var(--white);
    font-size: 14px;
    height: 30px;
}

.tablecaixa table tbody tr:nth-child(even) {
    background-color: #191919;
}

.tablecaixa table tbody tr:hover{
    background-color: #575757;
}


/* .estilocaixa{
    margin-bottom: 20px;
}

.estilocaixa button{
    width: 150px;
    background-color: transparent;
    border: 1px solid var(--red-900);
    display: flex;
    align-items: center;
}

.estilocaixa button svg{
    margin-right: 8px;
}

.estilocaixa button:hover{
    background-color: var(--red-900);
}

.lancarvalores{
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 90px;
    border: 1px solid var(--gray-100);
    padding: 15px;
    border-radius: 5px;
    margin-bottom: 10px;
}

.lancarvalores input{
    margin-right: 10px;
    margin-bottom: 0px;
}

.lancarvalores #valor{
    width: 120px;
}

.lancarvalores #desc{
    width: 300px;
}

.lancarvalores button{
    margin: 0px 10px;
    width: 100px;
}

.lancarvalores #deb{
    background-color: var(--red-700);
}

.lancarvalores #deb:hover{
    background-color: red;
}

.tablecaixa{
    width: 720px;
    height: calc(100vh - 290px);
    overflow-y: auto;
}

.caixavazio{
    margin-top: 20px;
    display: flex;
    justify-content: center;
}

.caixavazio h2{
    color: var(--white);
    font-style: italic;
}

.tablecaixa > table{
    border-radius: 5px;
    border: 1px solid var(--gray-100);
}

.tablecaixa > table > thead{
    background-color: rgb(170, 3, 3);
}

.tablecaixa > table > thead > tr > th{
    color: var(--white);
    font-size: 15px;
    padding: 5px;
}

.tablecaixa > table > tbody > tr > td{
    border: 1px solid var(--gray-100);
    color: var(--white);
    font-size: 17px;
    height: 30px;
} */