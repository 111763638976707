
.input{
    height: 35px;
    background-color: var(--dark-700);
    color: var(--white);
    padding: 10px;
    font-size: 15px;
    max-width: 500px;
    border: 0;
    width: 100%;
    border-radius: 5px;
    font-size: 15px;
}

.input::placeholder{
    color: rgba(255,255,255,0.7);
}