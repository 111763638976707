
.containerDashboard{
    background-color: black;
    width: 100%;
    height: calc(100vh - 135px);
    overflow: auto;
}

.div-content-dash{
    max-width: 1280px;
    width: 100%;
    margin: 0 auto;
}

.containerDashboard h3{
    color: var(--white);
    font-style: italic;
    text-align: center;
}