
.container-contato-full{
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
}

.sec-contato{
    background-color: #fff;
    padding-bottom: 50px;
}

.contato-container{
    height: 300px;
    width: 100%;
    margin-top: 5px;
}

.contato-texto-container{
    height: 300px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.contato-texto-container h1{
    font-family: "Caveat Brush", cursive, sans-serif;   
    font-optical-sizing: auto;
    font-weight: weight;
    font-style: italic;
    font-size: 58px;
}

.contato-texto-container h3{
    margin-bottom: 40px;
    font-family: "Caveat Brush", cursive, sans-serif;   
    font-optical-sizing: auto;
    font-weight: weight;
    font-style: italic;
    font-size: 26px;
    color: #575757;
    width: 75%;
}

.contato-form{
    background-color:  #b2ed9eab;
    height: 650px;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.contato-form form{
    height: 90%;
    width: 45%;
}

.contato-form form div{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 80%;
    margin: 10px auto;
    height: 70px;
}

.contato-form form div label{
    font-family: "Poppins", sans-serif;
    font-style: italic;
    font-size: 16px;
    font-weight: bold;
    color: #000;
}

.contato-form form div input{
    border: 0;
    height: 35px;
    width: 100%;
    border-radius: 7px;
    padding: 5px;
}

.form-textarea{
    height: 150px !important;
    justify-content: flex-start !important;
    padding-top: 10px;
}

.form-textarea textarea{
    resize: none;
    padding: 5px;
    border-radius: 7px;
    border: 0;
    width: 100%;
    height: 200px;
}

.contato-form form div button{
    width: 100%;
    height: 50px;
    margin: 0 auto;
    border: 0;
    border-radius: 7px;
    background-color: var(--red-900);
    color: #fff;
    font-size: 18px;
}

.contato-form form div button:hover{
    background-color: rgb(168, 0, 0);
}

.contato-form form div span, .contato-mapa span{
    font-family: "Poppins", sans-serif;
    font-size: 10px;
    font-weight: bold;
    margin-top: 10px;
    font-style: italic;
}

.contato-mapa{
    width: 45%;
    height: 88%;
    margin: 10px auto;
}

/* MOBILE */

@media screen and (max-width: 980px) {

    .contato-texto-container{
       width: 90%;
       margin: 0 auto;
    }

    .contato-texto-container h1{
        font-size: 36px;
        text-align: center;
        width: 100%;
    }

    .contato-texto-container h3{
        font-size: 20px;
        width: 100%;
        text-align: center;
    }

    .contato-form{
        flex-direction: column;
        height: 1000px;
        padding-bottom: 10px;
    }

    .contato-form form{
        width: 100%;
    }

    .contato-mapa{
        width: 88%;
    }
}
