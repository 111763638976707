
.button{
    height: 40px;
    border: 0;
    border-radius: 5px;
    background-color: green;
    color: var(--white);
    font-size: 18px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
}

.button:hover{
    background-color: var(--green-900);
    color: var(--white);
}