
.containerNotfound{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    flex-direction: column;
}

.notfound{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}

.notfound h1{
    color: var(--white);
    font-size: 50px;
}

.notfound span{
    color: var(--white);
   margin: 0 20px;
}

.notfound h2{
    color: var(--white);
}
