
.containerCart{
    background-color: #191919;
    width: 100%;
    height: 100%;
    max-height: 60%;
    display: flex;
    flex-direction: column;
}

.container-item{
    overflow-y: auto;
    height: 200px;
    border-bottom: 1px solid #575757;
}

.div-itens-cart{
    background-color: transparent;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    padding-top: 5px;
    padding-left: 5px;
}

.div-nomeitem {
    margin-bottom: 5px;
    padding-left: 5px;
    margin-top: 5px;
}

.div-nomeitem > span{
    color: #fff;
    font-size: 15px;
}

.div-valores{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 5px;
    border-bottom: 1px solid #fff;
}

.div-valores > svg{
    margin-right: 10px;
}

.div-valores > span{
    color: #fff;
    font-size: 15px;   
}

.div-total{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    padding-right: 10px;
    margin: 15px 0px 15px 0px;
}

.div-total > span{
    color: yellow;
    font-weight: bold;
    font-size: 17px;  
}

.div-nomecliente{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 5px;
    height: 40px;
}

.div-nomecliente > label, .div-obs > label{
    padding-left: 10px;
    color: #fff;
    font-size: 14px;
}

.div-nomecliente > input{
    height: 35px;
    margin-left: 5px;
    border: 0;
    border-radius: 5px;
    width: 80%;
    padding: 5px;
    background-color: #fff;
    color: black;
    text-transform: uppercase;
    font-size: 14px;
}

.div-obs{
    width: 100%;
    margin-top: 10px;
    display: flex;
}

.div-obs > textarea{
    padding: 5px;
    resize: none;
    height: 75px;
    width: 82%;
    border-radius: 5px;
    margin-left: 10px;
    text-transform: uppercase;
    font-size: 14px;
}

.div-btn-cart{
    width: 100%;
    height: 50px;
    display: flex;
    padding-top: 5px;
    margin-bottom: 5px;
    justify-content: space-around;
    align-items: center;
    border-top: 1px solid #fff;
}

.btn-cart{
    border: 0;
    height: 35px;
    width: 100px;
    border-radius: 5px;
    font-size: 12px;
    font-weight: bolder;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.btn-voltar{
    background-color: yellow;
    color: #000;
}

.btn-finalizar{
    background-color: green;
    color: white;
}

.btn-finalizar:disabled{
    background-color: #ccc;
    color: black;
}

.btn-finalizar:disabled > svg{
    display: none;
}