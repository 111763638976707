

.containerPedidos{
    background-color: black;
    height: calc(100vh - 135px);
    width: 100%;
    overflow: auto;
}

.div-content-pedido{
    border: 1px solid #575757;
    width: 100%;
    max-width: 1280px;
    height: 100%;
    margin: 0 auto;
    overflow: auto;
    border-radius: 5px;
}

.contentPedidoHeader{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    gap: 30px;
    border-bottom: 1px solid #575757;
}

.contentPedidoHeader h2{
    color: var(--white);
    font-size: 22px;
    font-weight: bold;
}

.contentPedidoHeader #novo{
    height: 35px;
    padding: 10px;
    border-radius: 5px;
    background-color: var(--green-900);
    color: var(--white);
    font-size: 14px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.contentPedidoHeader #novo:hover{
    background-color: green;
}

.contentPedidoHeader #refresh{
    border: 0;
    height: 35px;
    background-color: transparent;
    border-radius: 5px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: bold;
    gap: 10px;
    width: 150px;
}

.contentPedidoHeader #refresh:hover{
    background-color: var(--green-900);
    color: black;
}

.contentPedidos{
    width: 100%;
    height: calc(100vh - 200px);
    overflow: auto;
}

.contentmesa{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #191919;
    width: 50%;
    margin: 0 auto;
    height: 70px;
    margin-top: 5px;
    border-radius: 5px;
}

.contentmesa:hover{
    background-color: #575757;
}

.mesa{
    width: 70%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.mesa .tag{
    width: 13px;
    height: 100%;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.mesa button{
    width: 100%;
    height: 100%; 
    border: 0;  
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 15px;
    margin-left: 10px;
    color: #fff;
    font-size: 18px;
    font-weight: bold;
}

.edtMesa{
    width: 30%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
}

.edtMesa button{
    height: 40px;
    width: 40px;
    border-radius: 5px;
    border: 0;
    background-color: transparent;
}

.edtMesa button:hover{
    background-color: #191919;
}

span.txtpreparacao{
    font-size: 14px;
    color: var(--green-900);
    font-style: italic;
}

