
.containerModalPrint{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(255,255,255,0.3);
    z-index: 99;
}

.printDetalhePedido{
    display: none;
    flex-direction: column;
    background-color: #fff;
    width: 350px;
    height: auto;
}

.printDetalheInfo{
    margin-bottom: 20px;
}

.printDetalheInfo > h4{
    font-size: 28px;
    font-weight: bold;
    font-family: "Caveat Brush", cursive, sans-serif;
}

.printDetalheInfo > h5{
    font-size: 18px;
    font-weight: normal;
}

.printDetalheHeader{
    margin-bottom: 20px;
}

.printDetalheHeader > h4{
    font-size: 28px;
    font-weight: bold;
    font-family: "Caveat Brush", cursive, sans-serif;   
}

.printDetalheHeader > span{
    font-size: 18px;
}

.printDetalheDetail{
    margin-bottom: 5px;
}

.printDetalheDetail > span{
    font-size: 18px;
}

.print-Detalhe-obs{
    padding: 20px 0px 20px 0;
}

.print-Detalhe-obs > h6{
    font-size: 14px;
    font-weight: normal;
}

.print-Detalhe-obs > span{
    font-size: 15px;
    font-weight: normal;
}

.printDetalheFooter{
    border-bottom: 1px dashed #000;
    margin-right: 10px;
    margin-bottom: 5px;
}

.printDetalheFooter > h5{
    font-size: 12px;
    font-weight: normal; 
    text-align: right; 
    padding-bottom: 10px;  
}

@media print {
    .printDetalhePedido{
        display: flex;
    }
}

@page{
    size: auto;
    margin-top: 0mm;
    margin-left: 0mm;
    margin-right: 0mm;
    margin-bottom: 0.5mm;
  }