

.containerConta{
    background-color: black;
    height: calc(100vh - 135px);
    width: 100%;
    overflow: auto;
}

.div-container-conta{
    border: 1px solid #575757;
    height: 100%;
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
    border-radius: 5px;
}

.headerConta{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    height: 90px;
}

.headerConta span{
    margin: 10px 0px;
    color: var(--white);
    font-style: italic;
    font-size: 14px;
    font-weight: bold;
    line-height: 5px;
}

.boxcustomer{
    display: flex;
    align-items: center;
    justify-content: space-around;
    border: 1px solid #575757;
    width: 100%;
    max-width: 900px;
    margin: 0 auto;
    border-radius: 10px;
    box-shadow: 2px 2px 2px rgba(255,255,255,0.5);
}

.formConta{
    display: flex;
    flex-direction: column;
    max-width: 450px;
    width: 100%;
    margin-top: 10px;
}

.formConta > button{
    margin-top: 10px;
    font-size: 14px;
    font-weight: bold;
}

.container-planos{
    border: 1px solid var(--gray-100);
    height: 290px;
    width: 300px;
    border-radius: 10px;
    margin-left: 10px;
}

.div-txt-planos{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 0px;
}

.div-txt-planos > h5{
    color: white;
    font-size: 13px;
    font-style: italic;
}

.div-txt-planos > span{
    color: aliceblue;
    font-size: 18px;
    font-style: italic;
}

.div-btn-planos{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.div-btn-planos a{
    width: 250px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    color: white;
    background-color: #474747;
    font-size: 14px;
}

.div-btn-planos button{
    border: 0;
    width: 250px;
    height: 40px;
    margin: 10px 0px;
    border-radius: 5px;
    font-size: 14px;
    font-weight: bold;
}

.div-btn-planos #btn-mod1{
    background-color: rgb(205, 127, 50);
    color: #fff;
    
}

.div-btn-planos #btn-mod2{
    background-color: rgb(192,192,192);
}

.div-btn-planos #btn-mod3{
    background-color: rgb(255, 215, 0);
    color: black;
    transition: .2s;
}

.div-btn-planos a:hover{
    background-color: #333333;
    font-style: italic;
    box-shadow: 2px 2px 5px #8a8a8aa8;   
}

.div-btn-planos #btn-mod1:hover{
    background-color: rgb(255, 158, 61);
    font-style: italic;
    box-shadow: 2px 2px 5px #8a8a8aa8;
}

.div-btn-planos #btn-mod2:hover{
    background-color: rgb(245, 245, 245);
    font-style: italic;
    box-shadow: 2px 2px 5px #8a8a8aa8;
}

.div-btn-planos #btn-mod3:hover{
    background-color: rgb(250, 228, 103);
    font-weight: bold;
    font-style: italic;
    box-shadow: 2px 2px 5px #8a8a8aa8;
}

.div-planopremium{
    background-color: #474747;
    width: 250px;
    margin: 0 auto;
    height: 210px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding: 10px;
}

.div-planopremium > span{
    color: white;
    font-size: 16px;
    text-align: justify;
    font-family: cursive;
}

.div-planopremium > button{
    border: 0;
    height: 35px;
    padding: 5px;
    border-radius: 5px;
    font-size: 11px;
    width: 100%;
    font-style: italic;
    color: #fff;
    background-color: var(--red-900);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.div-planopremium > button:hover{
    font-weight: bold;
    font-style: italic;
    box-shadow: 2px 2px 5px #8a8a8aa8;
}

