
.containerNovoProd{
    background-color: black;
    height: calc(100vh - 135px);
    width: 100%;
}

.div-container-novoprod{
    border: 1px solid #575757;
    border-radius: 5px;
    height: 100%;
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
    overflow: auto;
}

.div-btnback{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #575757;
}

.div-btnback a{
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 5%;
}

.div-btnback h4{
    width: 55%;
    color: var(--white);
    font-size: 20px;
}

.container-form{
    height: calc(100vh - 175px);
    width: 100%;
}

.container-form .div-foto{
    height: 300px;
    width: 100%;
}

.div-foto p{
    color: #fff;
    font-size: 12px;
    font-style: italic;
    text-align: center;
    padding: 10px 0;
}

.div-foto > label{
    border: 1px solid var(--gray-100);
    height: 240px;
    width: 500px;
    margin: 0 auto;
    border-radius: 5px;
    background-color: var(--dark-700);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.div-foto > label span{
    opacity: 0.5;
    position: absolute;
}

.div-foto > label span:hover{
    opacity: 1;
    transform: scale(1.1);
    z-index: 99;
}

.div-foto > label input{
    display: none;
}

.preview{
    object-fit: cover;
    border-radius: 5px;
    width: 100%;
    height: 100%;
}

.linha{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.linha .camposprod{
    display: flex;
    flex-direction: column;
}

.camposprod label{
    color: #fff;
    font-style: italic;
    font-size: 14px;
    margin: 5px 0;
}

.camposprod select{
        height: 35px;
        width: 350px;
        background-color: var(--dark-700);
        border-radius: 5px;
        color: var(--white);
        padding: 5px;
        font-size: 13px;
}

.camposprod #id-cardapio{
    width: 80px;
}

.camposprod > input{
    height: 35px;
    width: 350px;
    border-radius: 5px;
    border: 1px solid var(--gray-100);
}

.div-textarea{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 15px auto;
    border-radius: 5px;
    max-width: 820px;
    width: 77%;
    border: 1px solid var(--gray-100);
}

.div-textarea > textarea{
    width: 100%;
    max-width: 100% !important;
    resize: none;
    height: 120px;
    border-radius: 5px;
}

.div-btn-salvarprod{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.div-btn-salvarprod button{
    width: 200px;
    gap: 10px;
    font-size: 16px;
    font-weight: bold;
}

.div-btn-salvarprod a{
    width: 200px;
    height: 40px;
    background-color: var(--red-900);
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
}

.div-btn-salvarprod a:hover{
    background-color: rgb(168, 0, 0);
}