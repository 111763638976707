
.container-card{
    display: none;
}

@media screen and (max-width: 768px) {
    
    .ocultarLinha{
        display: none !important;
    }

    .container-card{
        width: 100%;
        height: 100vh;
        background-color: black;
        display: block;
    }
    
    .topo-card{
        width: 100%;
        height: 120px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 5px;
        transition: all 2s;
        border-bottom: 2px solid #fff;
    }

    .topo-card img{
        object-fit: cover;
    }

    .texto-card{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 60%;
    }

    .texto-card > span:first-child{
        color: #fff;
        font-style: italic;
        font-weight: bold;
        font-size: 14px !important;
        width: 100%;
        height: 20px;
        text-align: center;
        font-family: "Caveat Brush", cursive, sans-serif; 
    }

    .texto-card  #nomebar{
        font-family: "Reggae One", system-ui, sans-serif;
        font-size: 20px;
        width: 100%;
        font-weight: bold;
        color: #fff;
        font-style: italic;
        text-align: center;
    }

    .div-finalizar{
        display: flex;
        align-items: center;
        justify-content: space-around;
        width: 100%;
        height: 30px;

    }

    .div-finalizar button{
        border: 0;
        height: 100%;
        width: 115px;
        border-radius: 5px;
        background-color: #C8011E;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        font-weight: bold;
    }

    .div-finalizar button > svg{
        animation: loadingsvg infinite 2s;
    }

    .obs{
        width: 100%;
        height: 70px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        border-bottom: 2px solid #ff0; 
    }

    .obs span{
        color: #fff;
        padding: 5px;
        text-align: center;
        font-size: 13px;
        width: 100%;
    }

    .obs input{
        height: 35px;
        width: 90%;
        border-radius: 4px;
        border: 0;
        margin-bottom: 4px;
        text-transform: uppercase;
        padding: 5px;
    }

    .menu-card{
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: flex-start; 
        gap: 10px;
        overflow-x: auto;
        padding: 0px 5px;
    }

    .btn-card{
        height: 35px;
        min-width: 180px;
        background-color: #191919;
        border: 1px solid #aaa;
        border-radius: 3px;
        color: #fff;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 12px;
    }

    .active{
        background-color: #C8011E !important;
        border: 1px solid #C8011E !important;
        box-shadow: 1px 1px 1px 1px rgba(255,255,255,0.3);
        height: 43px !important;
        font-size: 13px !important;
        transition: all 0.4s;
        font-weight: bold;
    }
    
    .div-container-itenscardapio{
        width: 97%;
        height: calc(100vh - 255px);
        overflow: auto;
        margin: 0 auto;
    }

    .div-empty{
        display: flex;
        width: 100%;
    }
    
    .itemcard{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        border: 1px solid #ccc;
        border-radius: 5px;
        margin: 10px 0;
        height: 155px;
    }

    .itemcard .containerProd{
        width: 60%;
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    .containerProd .dados-prod{
        height: 75%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        padding: 5px 5px 0px 5px;
    }

    .dados-prod h5{
        color: #fff;
        font-size: 12px;
        width: 100%;
        height: 30px;
    }

    .dados-prod > span{
        color: #ccc;
        font-size: 11px;
        height: 50%;
        width: 100%;
    }

    .dados-prod .div-valor{
        width: 100%;
        text-align: right;
        height: 20px;
    }

    .div-valor span{
        color: yellow;
        font-size: 16px;
        font-weight: bold;
    }

    .containerProd .container-add{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 5px 10px;
        height: 25%;
    }

    .container-add .btnmenos{
        border: 0;
        height: 35px;
        width: 35px;
        background-color: yellow;
        border-radius: 5px;
    }

    .container-add span{
        font-size: 18px;
        color: #fff;
    }

    .container-add #sp-qrcode{
        font-size: 9px;
        color: #fff;
    }

    .container-add .btnmais{
        border: 0;
        height: 35px;
        width: 35px;
        background-color: blue;
        border-radius: 5px;
    }

    .container-add .btnadd{
        border: 0;
        height: 35px;
        width: 80px;
        background-color: green;
        border-radius: 5px;
        color: #fff;
        font-size: 12px;
    }

    .itemcard .imgprod{
        width: 40%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .imgprod > img{
        object-fit: cover;
        width: 99%;
        height: 99%;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;        
    }

}

