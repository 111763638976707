
.swiper {
    width: 320px;
    height: 420px;
}
  
.swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 18px;
    font-size: 22px;
    font-weight: bold;
    color: #fff;
}
  
.swiper-slide:nth-child(1n) {
    background-color: rgb(206, 17, 17);
    display: flex;
    align-items: center;
    justify-self: center
}
  
.swiper-slide:nth-child(2n) {
    background-color: rgb(0, 140, 255);
}
  
.swiper-slide:nth-child(3n) {
    background-color: rgb(10, 184, 111);
}
  
.swiper-slide:nth-child(4n) {
    background-color: rgb(211, 122, 7);
}
  
.swiper-slide:nth-child(5n) {
    background-color: rgb(118, 163, 12);
}
  
.swiper-slide:nth-child(6n) {
    background-color: rgb(180, 10, 47);
}
  
.swiper-slide:nth-child(7n) {
    background-color: rgb(35, 99, 19);
}
  
.swiper-slide:nth-child(8n) {
    background-color: rgb(0, 68, 255);
}
  
.swiper-slide:nth-child(9n) {
    background-color: rgb(218, 12, 218);
}
  
.swiper-slide:nth-child(10n) {
    background-color: rgb(54, 94, 77);
}

/* COMECO TOPO 2 */

.sec-containerfunc{
    background-color: #eee;
    height: 500px;
    width: 100%;
}

.div-texto-banner2{
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.div-texto1{
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.div-texto1 > span{
    font-family: "Caveat Brush", cursive;
    font-weight: 400;
    font-size: 65px;
    width: 100%;
}

.div-texto1 ul{
    list-style: none;
    margin-top: 50px;
    height: calc(100% - 150px);
}

.div-texto1 ul li{
    font-family: "Caveat Brush", cursive;
    font-size: 24px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    margin: 10px 0;
}

.div-texto2{
    height: 100%;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.slide{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 50%;
    width: 80%;
    gap: 40px;
}

.slide span{
    font-weight: bold;
    font-size: 28px; 
    font-family: "Caveat Brush", cursive, sans-serif;   
}

/* MOBILE */

@media screen and (max-width: 980px) {

    .sec-containerfunc{
        height: auto;
    }

    .div-texto-banner2{
        flex-direction: column;
        align-items: center;
        height: auto;
    }

    .swiper{
        width: 210px;
        height: 310px;
    }

    .div-texto1{
        width: 100%;
    }

    .div-texto1 > span{
        font-size: 40px;
        padding-left: 20px;
        color: var(--red-900);    
    }

    .div-texto1 ul{
        padding-left: 20px;
    }

    .div-texto1 ul li{
        font-size: 18px;  
 
    }

    .div-texto1 ul li svg{
        width: 25px;
        height: 25px;
    }

    .slide span{
        font-size: 18px;
    }


}
