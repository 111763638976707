@import url('https://fonts.googleapis.com/css2?family=Caveat+Brush&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


*{
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}

html, body{
  font-family: sans-serif;
  height: calc(100vh - 160px);
  background-color: #000;
}

:root{
  --white: #FFF;
  --black: #000;

  --background: #191919;
  --dark-700: #1d1d2e;
  --gray-100: #8a8a8a;

  --green-900: #2ecc71;
  --green-700: #4DB89E;
  --red-900: #F21A22;
}

a{
  text-decoration: none;
  cursor: pointer;
}

button{
  cursor: pointer;
}

textarea{
  font-family: Arial, Helvetica, sans-serif;
}

@page{
  size: auto;
  margin-top: 0mm;
  margin-left: 0mm;
  margin-right: 0mm;
  margin-bottom: 0.5mm;
}