
.menumobile-box{
    background-color: black;
    height: 0%;
    position: absolute;
    left: 0;
    top: 0%;
    z-index: 999;
    width: 0%;
    opacity: 1;
    transition: all 1s;
    overflow: hidden;
}

.activemenu{
    opacity: 1;
    top: 0;
    height: 100%;
    width: 100%;
    transition: all 1s;
}

.btn-close{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    border-bottom: 1px solid var(--green-900);
}

.btn-close span{
    color: #fff;
    font-style: italic;
}

.btn-close button{
    border: 0;
    background: transparent;
}

.menumobile-box ul{
    height: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    list-style: none;
    gap: 20px;
}

.menumobile-box ul li{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--green-900);
    border-radius: 10px;
    width: 45%;
    height: 65px;
    gap: 5px;
    color: #fff;
    font-size: 12px;
    text-align: center;
}

.menumobile-box ul li a{
    color: #fff;
}