
.container{
    height: 120px;
    max-height: 120px;
    width: 100%;
    border-bottom: 3px solid var(--green-900);
    background-color: #000;
}

.content{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    height: 117px;
    margin: 0 auto;
    max-width: 1280px;
}

.logo{
    margin-top: 20px;
    margin-left: 20px;
    display: flex;
    flex-direction: column;
}

.logo span{
    color: #FFF;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    font-style: italic;
    margin-bottom: 10px;
}

.logo span a{
    color: var(--white);
    text-decoration: underline;
    font-style: normal;
}

.menu{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 117px;
    gap: 10px;
}

.menu a{
    color: #FFF;
    padding: 10px;
    border-radius: 5px;
    font-size: 14px;
    font-weight: bold;
    display: flex;
    align-items: center;
}

.menu a svg{
    margin-right: 8px;
}

.menu a:hover{
    background-color: var(--green-900);
    color: var(--black);
}

.sair{
    margin-left: 10px;
    border: 0;
    background-color: transparent;
    color: var(--white);
    font-size: 14px;
    font-weight: bold;
    display: flex;
    align-items: center;
    padding: 10px;
    border-radius: 5px;
}

.sair svg{
    margin-right: 8px;
}

.sair:hover{
    background-color: var(--green-900);
    color: var(--black);
}

