

.containerImg{
    width: 800px;
    margin: 0 auto;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    transition: all 2s;
    overflow-y: auto;
}

.containerImg > img{
    height: 100%;
    width: 100%;
    transition: all 2s;
    object-fit: cover;
    object-position: center;
}

@media screen and (max-width: 768px) {
    .containerImg{
        height: 100%;
        width: 100%;
    }
}


