
.containerMesas{
    background-color: black;
    height: calc(100vh - 135px);
    width: 100%;
    overflow: auto;
}

.div-content-mesas{
    border: 1px solid #575757;
    height: 100%;
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
    overflow: auto;
    border-radius: 5px;
}

.refresMesa{
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid var(--green-900);
    height: 60px;
    gap: 30px;
}

.containerMesas h1{
    color: var(--white);
    font-size: 20px;
}


.refresMesa button{
    background-color: transparent;
    border: 0;
    padding: 5px;
    border-radius: 5px;
    color: #fff;  
    display: flex;
    align-items: center;
    justify-content: center;  
    gap: 10px;
    font-size: 14px;
    font-weight: bold;
}

.refresMesa button:hover{
    background-color: var(--green-900); 
    color: #000; 
}

.gridMesas{
    display: grid;
    grid-template-columns: auto auto auto auto auto auto auto;

}

.gridItem{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    width: 140px;
    height: 140px;
    margin: 5px;
    border-radius: 5px;
    border: 1px solid var(--gray-100);
    color: var(--white);
    font-size: 20px;
}

.gridItem:hover{
    background-color: var(--gray-100);
}

.gridItem button{
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 0;
    background-color: transparent;
    width: 100%;
}

.gridItem span{
    color: var(--white);
    font-size: 25px;
    margin-top: 5px;
    font-weight: bold;
}

.gridItem p{
    color: var(--white);
}