
.transfMesa > h4{
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    margin-left: 50px;
    font-style: italic;
}

.div-ref-mesas{
    height: 150px;
    display: flex;
    border: 2px solid #000000a9;
    margin-top: 10px;
    border-radius: 5px;
    box-shadow: 2px 2px 2px rgba(0,0,0,0.5);
}

.div-input-transf{
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.div-input-transf > label{
    color: #000;
    font-size: 16px;
    font-style: italic;
}

.div-input-transf > input{
    height: 40px;
    width: 100px;
    border: 1px solid #aaa;
    border-radius: 5px;
    padding: 10px;
    font-size: 20px;
    font-weight: bold;
}

.btn-salvar-transnf{
    border: 0;
    background-color: green;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    font-size: 14px;
    font-weight: bold;
}

.btn-salvar-transnf:hover{
    background-color: var(--green-900);
}

.txt-atencao{
    display: flex;
    flex-direction: column;
    margin-top: 15px;
}

.txt-atencao > h2{
    font-size: 14px;
    font-style: italic;
    text-align: center;
    font-weight: bold;
    color: var(--red-900);
    text-transform: uppercase;
}

