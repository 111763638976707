

form > button{
    margin-bottom: 20px;
}

.cad{
    height: 450px;
    width: 550px;
}

.div-linha{
    display: flex;
    align-items: center;
    height: 40px;
    border: 1px solid var(--gray-100);
    background-color: var(--dark-700);
    margin-top: 10px;
    border-radius: 5px;
    padding-left: 10px;
}

.svg-enviar{
    animation: anime 1s linear infinite;
}

@keyframes anime{
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.recaptcha{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 15px;
}