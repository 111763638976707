
.div-quemsomos{
    width: 100%;
    background-color: #f3f3f3;
    height: 600px;
    margin-top: 5px;
}

.quemsomos-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 600px;
    overflow: auto;
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
}

.quemsomos-container div img{
    width: 300px;
    object-fit: cover;
    margin-right: 20px;
}

.quemsomos-container div p{
    font-size: 26px;
    text-align: justify;
    padding: 0 20px 0 20px;
    margin-bottom: 30px;
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-style: italic;   
}

/* MOBILE */

@media screen and (max-width: 980px) {

    .quemsomos-container{
        flex-direction: column;
        margin-top: 15px;
        height: 550px;
    }

    .quemsomos-container div img{
        width: 200px;
    }

    .quemsomos-container div p{
        margin-top: 10px;
        font-size: 14px;
    }
}